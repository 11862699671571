function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
/*! modernizr 3.3.1 (Custom Build) | MIT *
 * https://modernizr.com/download/?-csscolumns-flexbox-inlinesvg-svg-setclasses !*/
!function (e, n, t) {
  function r(e, n) {
    return _typeof(e) === n;
  }
  function s() {
    var e, n, t, s, o, i, a;
    for (var l in w) if (w.hasOwnProperty(l)) {
      if (e = [], n = w[l], n.name && (e.push(n.name.toLowerCase()), n.options && n.options.aliases && n.options.aliases.length)) for (t = 0; t < n.options.aliases.length; t++) e.push(n.options.aliases[t].toLowerCase());
      for (s = r(n.fn, "function") ? n.fn() : n.fn, o = 0; o < e.length; o++) i = e[o], a = i.split("."), 1 === a.length ? Modernizr[a[0]] = s : (!Modernizr[a[0]] || Modernizr[a[0]] instanceof Boolean || (Modernizr[a[0]] = new Boolean(Modernizr[a[0]])), Modernizr[a[0]][a[1]] = s), y.push((s ? "" : "no-") + a.join("-"));
    }
  }
  function o(e) {
    var n = S.className,
      t = Modernizr._config.classPrefix || "";
    if (x && (n = n.baseVal), Modernizr._config.enableJSClass) {
      var r = new RegExp("(^|\\s)" + t + "no-js(\\s|$)");
      n = n.replace(r, "$1" + t + "js$2");
    }
    Modernizr._config.enableClasses && (n += " " + t + e.join(" " + t), x ? S.className.baseVal = n : S.className = n);
  }
  function i() {
    return "function" != typeof n.createElement ? n.createElement(arguments[0]) : x ? n.createElementNS.call(n, "http://www.w3.org/2000/svg", arguments[0]) : n.createElement.apply(n, arguments);
  }
  function a(e, n) {
    return !!~("" + e).indexOf(n);
  }
  function l(e) {
    return e.replace(/([a-z])-([a-z])/g, function (e, n, t) {
      return n + t.toUpperCase();
    }).replace(/^-/, "");
  }
  function f(e, n) {
    return function () {
      return e.apply(n, arguments);
    };
  }
  function u(e, n, t) {
    var s;
    for (var o in e) if (e[o] in n) return t === !1 ? e[o] : (s = n[e[o]], r(s, "function") ? f(s, t || n) : s);
    return !1;
  }
  function d(e) {
    return e.replace(/([A-Z])/g, function (e, n) {
      return "-" + n.toLowerCase();
    }).replace(/^ms-/, "-ms-");
  }
  function c() {
    var e = n.body;
    return e || (e = i(x ? "svg" : "body"), e.fake = !0), e;
  }
  function p(e, t, r, s) {
    var o,
      a,
      l,
      f,
      u = "modernizr",
      d = i("div"),
      p = c();
    if (parseInt(r, 10)) for (; r--;) l = i("div"), l.id = s ? s[r] : u + (r + 1), d.appendChild(l);
    return o = i("style"), o.type = "text/css", o.id = "s" + u, (p.fake ? p : d).appendChild(o), p.appendChild(d), o.styleSheet ? o.styleSheet.cssText = e : o.appendChild(n.createTextNode(e)), d.id = u, p.fake && (p.style.background = "", p.style.overflow = "hidden", f = S.style.overflow, S.style.overflow = "hidden", S.appendChild(p)), a = t(d, e), p.fake ? (p.parentNode.removeChild(p), S.style.overflow = f, S.offsetHeight) : d.parentNode.removeChild(d), !!a;
  }
  function m(n, r) {
    var s = n.length;
    if ("CSS" in e && "supports" in e.CSS) {
      for (; s--;) if (e.CSS.supports(d(n[s]), r)) return !0;
      return !1;
    }
    if ("CSSSupportsRule" in e) {
      for (var o = []; s--;) o.push("(" + d(n[s]) + ":" + r + ")");
      return o = o.join(" or "), p("@supports (" + o + ") { #modernizr { position: absolute; } }", function (e) {
        return "absolute" == getComputedStyle(e, null).position;
      });
    }
    return t;
  }
  function v(e, n, s, o) {
    function f() {
      d && (delete E.style, delete E.modElem);
    }
    if (o = r(o, "undefined") ? !1 : o, !r(s, "undefined")) {
      var u = m(e, s);
      if (!r(u, "undefined")) return u;
    }
    for (var d, c, p, v, h, g = ["modernizr", "tspan", "samp"]; !E.style && g.length;) d = !0, E.modElem = i(g.shift()), E.style = E.modElem.style;
    for (p = e.length, c = 0; p > c; c++) if (v = e[c], h = E.style[v], a(v, "-") && (v = l(v)), E.style[v] !== t) {
      if (o || r(s, "undefined")) return f(), "pfx" == n ? v : !0;
      try {
        E.style[v] = s;
      } catch (y) {}
      if (E.style[v] != h) return f(), "pfx" == n ? v : !0;
    }
    return f(), !1;
  }
  function h(e, n, t, s, o) {
    var i = e.charAt(0).toUpperCase() + e.slice(1),
      a = (e + " " + b.join(i + " ") + i).split(" ");
    return r(n, "string") || r(n, "undefined") ? v(a, n, s, o) : (a = (e + " " + T.join(i + " ") + i).split(" "), u(a, n, t));
  }
  function g(e, n, r) {
    return h(e, t, t, n, r);
  }
  var y = [],
    w = [],
    C = {
      _version: "3.3.1",
      _config: {
        classPrefix: "",
        enableClasses: !0,
        enableJSClass: !0,
        usePrefixes: !0
      },
      _q: [],
      on: function on(e, n) {
        var t = this;
        setTimeout(function () {
          n(t[e]);
        }, 0);
      },
      addTest: function addTest(e, n, t) {
        w.push({
          name: e,
          fn: n,
          options: t
        });
      },
      addAsyncTest: function addAsyncTest(e) {
        w.push({
          name: null,
          fn: e
        });
      }
    },
    Modernizr = function Modernizr() {};
  Modernizr.prototype = C, Modernizr = new Modernizr(), Modernizr.addTest("svg", !!n.createElementNS && !!n.createElementNS("http://www.w3.org/2000/svg", "svg").createSVGRect);
  var S = n.documentElement,
    x = "svg" === S.nodeName.toLowerCase();
  Modernizr.addTest("inlinesvg", function () {
    var e = i("div");
    return e.innerHTML = "<svg/>", "http://www.w3.org/2000/svg" == ("undefined" != typeof SVGRect && e.firstChild && e.firstChild.namespaceURI);
  });
  var _ = "Moz O ms Webkit",
    b = C._config.usePrefixes ? _.split(" ") : [];
  C._cssomPrefixes = b;
  var T = C._config.usePrefixes ? _.toLowerCase().split(" ") : [];
  C._domPrefixes = T;
  var k = {
    elem: i("modernizr")
  };
  Modernizr._q.push(function () {
    delete k.elem;
  });
  var E = {
    style: k.elem.style
  };
  Modernizr._q.unshift(function () {
    delete E.style;
  }), C.testAllProps = h, C.testAllProps = g, function () {
    Modernizr.addTest("csscolumns", function () {
      var e = !1,
        n = g("columnCount");
      try {
        (e = !!n) && (e = new Boolean(e));
      } catch (t) {}
      return e;
    });
    for (var e, n, t = ["Width", "Span", "Fill", "Gap", "Rule", "RuleColor", "RuleStyle", "RuleWidth", "BreakBefore", "BreakAfter", "BreakInside"], r = 0; r < t.length; r++) e = t[r].toLowerCase(), n = g("column" + t[r]), ("breakbefore" === e || "breakafter" === e || "breakinside" == e) && (n = n || g(t[r])), Modernizr.addTest("csscolumns." + e, n);
  }(), Modernizr.addTest("flexbox", g("flexBasis", "1px", !0)), s(), o(y), delete C.addTest, delete C.addAsyncTest;
  for (var N = 0; N < Modernizr._q.length; N++) Modernizr._q[N]();
  e.Modernizr = Modernizr;
}(window, document);